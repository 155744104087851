<template>
  <div>
    <b-row>
      <b-col sm="12" md="12">
        <div class="d-flex justify-content-center">
          <b-button-group class="mb-2">
            <b-button
              :disabled="button.add"
              pill
              variant="success"
              @click="addclick()"
              >เพิ่ม</b-button
            >
            <b-button
              :disabled="button.edit"
              pill
              variant="warning"
              @click="editclick()"
              >แก้ไข</b-button
            >
            <b-button
              :disabled="button.cancel"
              pill
              variant="secondary"
              @click="cancelClick()"
              >ยกเลิก</b-button
            >

            <b-button
              :disabled="button.delete"
              pill
              variant="danger"
              @click="deleteClick()"
              >ลบ</b-button
            >
            <b-button
              :disabled="button.search"
              pill
              variant="primary"
              @click="showmodalActivity"
              >ค้นหา</b-button
            >
          </b-button-group>
        </div>
      </b-col>
    </b-row>

    <b-card title="บันทึกการปฏิบัติงาน" class="mb-3">
      <b-card-body>
        <b-row>
          <b-col sm="12" md="3">
            <b-form-group id="labelCompny" label="สังกัด" label-for="cusgroup">
              <multiselect
                v-model.trim="$v.data.groupCodeId.$model"
                :class="{
                  'is-invalid': validationStatus($v.data.groupCodeId),
                }"
                :options="obj.groupCode"
                :disabled="disabled"
                label="groupCodeDesc"
                track-by="groupCodeId"
                placeholder=""
                :searchable="false"
                :close-on-select="true"
                :show-labels="false"
              ></multiselect>
              <span
                v-if="!$v.data.groupCodeId.required"
                class="invalid-feedback"
              >
                ยังไม่ได้เลือกสังกัด
              </span>
            </b-form-group>
          </b-col>

          <b-col sm="12" md="9">
            <b-form-group
              id="labelCompny"
              label="ชื่อลูกค้า"
              label-for="customer"
            >
              <multiselect
                v-model.trim="$v.data.customer.$model"
                :class="{
                  'is-invalid': validationStatus($v.data.customer),
                }"
                :options="obj.customer"
                :disabled="disabled"
                label="CardName"
                track-by="CardCode"
                :custom-label="labelWithCity"
                placeholder=""
                :searchable="true"
                :close-on-select="true"
                :show-labels="false"
                @select="getcontract(data.customer.CardCode)"
              >
                <!-- affichage du multiselect plié -->
                <template slot="singleLabel" slot-scope="{ option }">
                  <strong>{{ option.CardName }}</strong>
                  <span style="color: red">&nbsp;{{ option.City }} &nbsp;</span>
                  <span style="color: red">&nbsp;{{ option.County }}</span>
                </template>
                <!-- affichage des options(multiselect déplié) -->
                <template slot="option" slot-scope="{ option }">
                  <div class="option__desc">
                    <span class="option__title">
                      <strong>{{ option.CardName }}&nbsp;</strong>
                    </span>
                    <span class="option__small" style="color: green"
                      >{{ option.City }} &nbsp;</span
                    >
                    <span class="option__small" style="color: green">{{
                      option.County
                    }}</span>
                  </div>
                </template>
              </multiselect>
              <span v-if="!$v.data.customer.required" class="invalid-feedback">
                ยังไม่ได้เลือกชื่อลูกค้า
              </span>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="12">
            <b-form-group label="ชื่อผู้ติดต่อ">
              <multiselect
                v-model.trim="$v.data.contract.$model"
                :class="{
                  'is-invalid': validationStatus($v.data.contract),
                }"
                :options="obj.contract"
                :disabled="disabled"
                :custom-label="labelcontract"
                label="Name"
                track-by="CntCtCode"
                placeholder=""
                :searchable="false"
                :close-on-select="true"
                :show-labels="false"
              >
                <!-- affichage du multiselect plié -->
                <template slot="singleLabel" slot-scope="{ option }">
                  <strong>{{ option.Name }}</strong>
                  <span style="color: red"
                    >&nbsp; เบอรโทร&nbsp; {{ option.Tel1 }} &nbsp;</span
                  >
                  <span style="color: red"
                    >&nbsp; ตำแหน่ง&nbsp;{{ option.Position }}</span
                  >
                </template>
                <!-- affichage des options(multiselect déplié) -->
                <template slot="option" slot-scope="{ option }">
                  <div class="option__desc">
                    <span class="option__title">
                      <strong>{{ option.Name }}&nbsp;</strong>
                    </span>
                    <span class="option__small" style="color: green"
                      >เบอร์โทร &nbsp;{{ option.Tel1 }} &nbsp;</span
                    >
                    <span class="option__small" style="color: green">
                      ตำแหน่ง &nbsp;{{ option.Position }}</span
                    >
                  </div>
                </template>
              </multiselect>
              <span v-if="!$v.data.contract.required" class="invalid-feedback">
                ยังไม่ได้เลือกผู้ติดต่อ
              </span>
              <b-input-group-append>
                <b-button
                  title="เพิ่มข้อมูลผู้ติดต่อ"
                  text="Button"
                  @click="showModalcontract"
                  variant="success"
                  :disabled="disabled"
                  ><span class="mdi mdi-plus-box"></span>
                </b-button>
              </b-input-group-append>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col sm="12" md="12">
            <b-form-group label="กลุ่มสาระ/หมวด/แผนก ที่ติดต่อ">
              <multiselect
                v-model.trim="$v.data.learnGroup.$model"
                :class="{
                  'is-invalid': validationStatus($v.data.learnGroup),
                }"
                :options="obj.learnGroup"
                :disabled="disabled"
                label="subName"
                track-by="subId"
                placeholder=""
                :searchable="false"
                :close-on-select="true"
                :show-labels="false"
                :multiple="true"
              >
              </multiselect>
              <span
                v-if="!$v.data.learnGroup.required"
                class="invalid-feedback"
              >
                ยังไม่ได้ใส่เลือกกลุ่มสาระที่ติดต่อ
              </span>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col sm="12" md="12">
            <b-form-group label="รายละเอียดการปฏิบัติงาน">
              <b-form-textarea
                v-model.trim="$v.data.detail.$model"
                :class="{
                  'is-invalid': validationStatus($v.data.detail),
                }"
                :disabled="disabled"
              >
              </b-form-textarea>
              <span v-if="!$v.data.detail.required" class="invalid-feedback">
                ยังไม่ได้ใส่รายละเอียดการปฏิบัติงาน
              </span>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col sm="12" md="12">
            <b-form-group
              label="เลือกช่วงการปฏิบัติงาน"
              v-slot="{ ariaDescribedby }"
            >
              <b-form-radio-group
                :disabled="disabled"
                v-model.trim="$v.data.options.$model"
                :class="{
                  'is-invalid': validationStatus($v.data.options),
                }"
                :options="radio.options"
                :aria-describedby="ariaDescribedby"
                name="radio-inline"
              ></b-form-radio-group>
              <span v-if="!$v.data.options.required" class="invalid-feedback">
                ยังไม่ได้เลือกช่วงการปฏิบัติงาน
              </span>
            </b-form-group>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <div v-show="data.options == '1'">
      <b-card title="แบบตอบรับ">
        <b-card-body>
          <b-row>
            <b-col sm="12" md="12">
              <b-form-group label="เลือกแบบตอบรับ" v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                  :disabled="disabled"
                  v-model.trim="$v.survey.$model"
                  :class="{
                    'is-invalid': validationStatus($v.survey),
                  }"
                  :options="radio.survey"
                  :aria-describedby="ariaDescribedby"
                  name="survey"
                ></b-form-radio-group>
                <span v-if="!$v.survey.required" class="invalid-feedback">
                  กรุณาเลือกแบบตอบรับ
                </span>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </div>

    <div v-show="data.options == '2'">
      <b-card title="การประสานงานและการเก็บออเดอร์">
        <b-card-body>
          <b-row>
            <b-col sm="12" md="12">
              <b-form-group
                label="เลือกผลการเจรจา"
                v-slot="{ ariaDescribedby }"
              >
                <b-form-radio-group
                  :disabled="disabled"
                  v-model.trim="$v.saleProcess.$model"
                  :class="{
                    'is-invalid': validationStatus($v.saleProcess),
                  }"
                  :options="radio.saleProcess"
                  :aria-describedby="ariaDescribedby"
                  name="saleProcess"
                ></b-form-radio-group>
                <span v-if="!$v.saleProcess.required" class="invalid-feedback">
                  กรุณาเลือกผลการเจรจา
                </span>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </div>

    <div v-show="data.options == '3'">
      <b-card title="ประสานงานช่วงที่ 3">
        <b-card-body>
          <b-row>
            <b-col sm="12" md="12">
              <b-form-group
                label="เลือกรูปแบบการปฏิบัติงาน"
                v-slot="{ ariaDescribedby }"
              >
                <b-form-radio-group
                  :disabled="disabled"
                  v-model.trim="$v.clearProcess.$model"
                  :class="{
                    'is-invalid': validationStatus($v.clearProcess),
                  }"
                  :options="radio.clearProcess"
                  :aria-describedby="ariaDescribedby"
                  name="clearProcess"
                ></b-form-radio-group>
                <span v-if="!$v.clearProcess.required" class="invalid-feedback">
                  กรุณาเลือกแบบแผนการปฏิบัติงานช่วงที่ 3
                </span>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" md="6">
              <b-form-group
                label="เก็บเงินบางส่วน"
                v-show="clearProcess == '4'"
              >
                <b-form-input
                  :disabled="disabled"
                  @keypress="isNumber($event)"
                  v-model.number.trim="$v.receipt.$model"
                  :class="{
                    'is-invalid': validationStatus($v.receipt),
                  }"
                >
                </b-form-input>
                <span v-if="!$v.receipt.required" class="invalid-feedback">
                  กรุณาเลือกแบบแผนการปฏิบัติงานช่วงที่ 3
                </span>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="12" md="6" v-show="clearProcess == '5'">
              <b-form-group label="เก็บเงินปิดบัญชี">
                <b-form-input
                  :disabled="disabled"
                  @keypress="isNumber($event)"
                  v-model.number.trim="$v.receipt.$model"
                  :class="{
                    'is-invalid': validationStatus($v.receipt),
                  }"
                >
                </b-form-input>
                <span v-if="!$v.receipt.required" class="invalid-feedback">
                  กรุณาใส่ยอดเงิน
                </span>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </div>

    <div v-show="data.options == '4'">
      <b-card title="การปฏิบัติงานฝ่ายขายมัธยม">
        <b-card-body>
          <b-row>
            <b-col sm="12" md="12">
              <b-form-group
                label="เลือกการปฏิบัติงาน"
                v-slot="{ ariaDescribedby }"
              >
                <b-form-radio-group
                  :disabled="disabled"
                  v-model.trim="$v.matayomProcess.$model"
                  :class="{
                    'is-invalid': validationStatus($v.matayomProcess),
                  }"
                  :options="radio.mathayomProcess"
                  :aria-describedby="ariaDescribedby"
                  name="mathatomProcess"
                ></b-form-radio-group>
                <span
                  v-if="!$v.matayomProcess.required"
                  class="invalid-feedback"
                >
                  กรุณาเลือกการปฏิบัติงาน
                </span>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </div>

    <div v-show="data.options == '5'">
      <b-card title="การปฏิบัติงานฝ่ายขาย กทม/ปริมณฑล">
        <b-card-body>
          <b-row>
            <b-col sm="12" md="12">
              <b-form-group
                label="เลือกการปฏิบัติงาน"
                v-slot="{ ariaDescribedby }"
              >
                <b-form-radio-group
                  :disabled="disabled"
                  v-model.trim="$v.bangkok.$model"
                  :class="{
                    'is-invalid': validationStatus($v.bangkok),
                  }"
                  :options="radio.bangkok"
                  :aria-describedby="ariaDescribedby"
                  name="bangkokProcess"
                ></b-form-radio-group>
                <span v-if="!$v.bangkok.required" class="invalid-feedback">
                  กรุณาเลือกการปฏิบัติงาน
                </span>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </div>

    <b-card title="ภาพประกอบการปฏิบัติงาน" class="mt-3">
      <b-card-body>
        <b-row>
          <b-col sm="12" md="12">
            <b-col>
              <div class="d-flex mb-3">
                <b-form-file
                  :disabled="disabled"
                  v-model="image"
                  placeholder="Choose an image"
                  class="w-auto flex-grow-1"
                  accept="image/*"
                  type="file"
                ></b-form-file>
                <b-button
                  :disabled="disabled"
                  v-if="hasImage"
                  variant="danger"
                  class="ml-3"
                  @click="clearImage"
                  >ลบ</b-button
                >
              </div>

              <b-img
                v-if="hasImage"
                :src="imageSrc"
                class="mb-3"
                fluid
                block
                img-height="200"
                img-width="200"
                alt="Responsive image"
              ></b-img>
            </b-col>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>

    <b-button
      class="mt-3"
      :disabled="button.save"
      block
      variant="primary"
      @click="saveActivity"
      >บันทึก</b-button
    >

    <b-modal centered id="modal-addcontract" hide-footer>
      <template #modal-title>เพิ่มข้อมูลผู้ติดต่อ</template>
      <b-row>
        <b-col sm="12" md="12">
          <b-form-group
            label-cols-sm="12"
            label-cols-md="3"
            label="ชื่อผู้ติดต่อ"
            label-for=""
          >
            <b-form-input
              v-model.trim="$v.contract.name.$model"
              :class="{
                'is-invalid': validationStatus($v.contract.name),
              }"
            ></b-form-input>
            <span v-if="!$v.contract.name.required" class="invalid-feedback">
              ยังไม่ได้ใส่ผู้ติดต่อ
            </span>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12 md=12">
          <b-form-group
            label-cols-sm="12"
            label-cols-md="3"
            label="เบอร์โทร"
            label-for="inputtel"
          >
            <b-form-input
              v-model.trim="$v.contract.tel1.$model"
              :class="{
                'is-invalid': validationStatus($v.contract.tel1),
              }"
            >
            </b-form-input>
            <span v-if="!$v.contract.tel1.required" class="invalid-feedback">
              ยังไม่ได้ใส่เบอร์โทร
            </span>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" md="12">
          <b-form-group
            label-cols-sm="12"
            label-cols-md="3"
            label="ตำแหน่ง"
            label-for="inputPosition"
          >
            <b-form-input
              v-model.trim="$v.contract.position.$model"
              :class="{
                'is-invalid': validationStatus($v.contract.position),
              }"
            ></b-form-input>
            <span
              v-if="!$v.contract.position.required"
              class="invalid-feedback"
            >
              ยังไม่ได้ใส่ตำแหน่ง
            </span>
          </b-form-group>
        </b-col>
      </b-row>

      <b-button class="mt-3" variant="success" block @click="addcontract"
        >เพิ่ม</b-button
      >
      <b-button
        class="mt-3"
        variant="info"
        block
        @click="$bvModal.hide('modal-addcontract')"
        >ปิด</b-button
      >
    </b-modal>

    <b-modal centered id="modal-searchActivity" size="lg" hide-footer>
      <template #modal-title>ค้นหาข้อมูลการปฏิบัติงาน</template>

      <b-row>
        <b-col sm="12" md="5">
          <b-form-group label="วันที่">
            <b-form-datepicker
              id="datepicker-valid"
              today-button
              reset-button
              close-button
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              locale="th"
              v-model.trim="$v.search.fromDate.$model"
              :class="{
                'is-invalid': validationStatus($v.search.fromDate),
              }"
            ></b-form-datepicker>
            <span v-if="!$v.search.fromDate.required" class="invalid-feedback">
              ยังไมได้ใส่วันที่เอกสาร
            </span>
          </b-form-group>
        </b-col>

        <b-col sm="12" md="5">
          <b-form-group label="วันที่">
            <b-form-datepicker
              id="datepicker-from"
              today-button
              reset-button
              close-button
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              locale="th"
              v-model.trim="$v.search.toDate.$model"
              :class="{
                'is-invalid': validationStatus($v.search.toDate),
              }"
            ></b-form-datepicker>
            <span v-if="!$v.search.toDate.required" class="invalid-feedback">
              ยังไมได้ใส่วันที่เอกสาร
            </span>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="2">
          <b-button variant="warning" class="md-mt-4 mt-4" @click="getActivity"
            >ค้นหา</b-button
          >
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="12" md="12">
          <b-card>
            <b-card-body>
              <b-table
                fixed
                show-empty
                hover
                responsive
                sticky-header="600px"
                :items="datatable.items"
                :fields="datatable.fields"
                :busy.sync="datatable.isBusy"
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>

                <template v-slot:cell(index)="row">
                  {{ row.index + 1 }}
                </template>

                <template #cell(docDate)="row">
                  {{ formatDate(row.item.docDate) }}
                </template>

                <template #cell(action)="row">
                  <b-button
                    size="sm"
                    class="mr-1"
                    pill
                    variant="outline-info"
                    @click="selectActivity(row.item.activityId)"
                  >
                    เลือก
                  </b-button>
                </template>
              </b-table>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import swal from "sweetalert2";
import moment from "moment";
//import moment from "moment";

const base64Encode = (data) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default {
  name: "Starter",
  data: () => ({
    image: null,
    imageSrc: null,

    obj: {
      groupCode: [],
      customer: [],
      contract: [],
      learnGroup: [],
    },
    disabled: true,

    search: {
      fromDate: "",
      toDate: "",
    },

    data: {
      customer: [],
      groupCodeId: "",
      options: "1",
      contract: [],
      learnGroup: [],
      detail: "",
      activityId: "",
      docDate: "",
      editStatus: "",
    },

    survey: "",
    saleProcess: "",

    clearProcess: "",
    receipt: "",

    matayomProcess: "",
    bangkok: "",

    contract: {
      name: "",
      tel1: "",
      position: "",
    },

    radio: {
      options: [
        { text: "ฝ่ายประถมช่วงที่ 1", value: "1" },
        { text: "ฝ่ายประถมช่วงที่ 2", value: "2" },
        { text: "ฝ่ายประถมช่วงที่ 3", value: "3" },
        { text: "ฝ่ายมัธยม", value: "4" },
        { text: "ฝ่ายกทม/ปริมณฑล", value: "5" },
      ],
      survey: [
        { text: "น้อยกว่า 4 สาระ", value: "1" },
        { text: "มากกว่า 4 สาระ", value: "2" },
        { text: "ไม่ซื้อ", value: "3" },
      ],
      saleProcess: [
        { text: "รับตรงผ่านตัวแทน", value: "1" },
        { text: "ผ่านร้านค้าตัวแทน", value: "2" },
      ],
      clearProcess: [
        { text: "เคลียร์ยอด/รับคืน", value: "1" },
        { text: "แจ้งทำเอกสาร", value: "2" },
        { text: "วางบิล", value: "3" },
        { text: "เก็บเงินบางส่วน", value: "4" },
        { text: "เก็บเงินปิดบัญชี", value: "5" },
      ],
      mathayomProcess: [
        { text: "วางตัวอย่าง", value: "1" },
        { text: "นำเสนอ", value: "2" },
        { text: "ปิดการขาย", value: "3" },
        { text: "ติดตามใบจองหมวด", value: "4" },
        { text: "ติดตามฎีกา", value: "5" },
        { text: "ติดตามเอกสารเบิกค่าสนับสนุน", value: "6" },
        { text: "ประสานทำเอกสาร", value: "7" },
        { text: "วางบิล", value: "8" },
        { text: "รับคืน", value: "9" },
        { text: "เก็บเงิน", value: "10" },
      ],
      bangkok: [
        { text: "วางตัวอย่าง", value: "1" },
        { text: "นำเสนอ", value: "2" },
        { text: "ติดตามออเดอร์", value: "3" },
        { text: "ติดตามใบจองหมวด", value: "4" },
        { text: "ติดตามฎีกา", value: "5" },
        { text: "ประสานทำเอกสาร", value: "6" },
        { text: "ส่งหนังสือ", value: "7" },
        { text: "จ่ายค่าสนับสนุนกิจกรรม", value: "8" },
        { text: "รับคืน", value: "9" },
        { text: "วางบิล", value: "10" },
        { text: "เก็บเงิน", value: "11" },
        { text: "อื่น", value: "12" },
      ],
    },

    button: {
      add: false,
      edit: true,
      delete: true,
      search: false,
      cancel: true,
      save: true,
    },

    datatable: {
      items: [],
      fields: [
        {
          key: "index",
          label: "ลำดับ",
          thStyle: { width: "100px" },
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "docDate",
          label: "วันที่",
          thStyle: { width: "150px" },
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "cardName",
          label: "ชื่อลูกค้า",
          thStyle: { width: "200px" },
          thClass: "text-center",
          tdClass: "text-center",
        },
        {
          key: "action",
          label: "เลือก",
          thStyle: { width: "80px" },
          thClass: "text-center",
          tdClass: "text-center",
        },
      ],
    },
  }),

  computed: {
    hasImage() {
      return !!this.imageSrc;
    },
  },

  watch: {
    image(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then((value) => {
              this.imageSrc = value;
            })
            .catch(() => {
              this.imageSrc = null;
            });
        } else {
          this.imageSrc = null;
        }
      }
    },
  },

  methods: {
    async clearImage() {
      try {
        if (this.data.activityId == "") {
          this.image = null;
          this.imageSrc = null;
          return;
        }

        var response = await this.$http({
          method: "Delete",
          url: "api/SaleMan/DeletePicActivity/" + this.data.activityId,
        });

        if (response.status == 200) {
          this.image = null;
          this.imageSrc = null;
        }
      } catch (error) {
        if (error.response.status == 404) {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.response.data,
          });
        } else if (error.response.status == 401) {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: "ไม่พบสิทธิ์การใช้งาน",
          });
        } else if (error.response.status == 403) {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: "ไม่พบสิทธิ์การใช้งาน",
          });
        } else if (error.response.status == "500") {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.response.data.detail,
          });
        } else if (error.response.status == "400") {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.response.data,
          });
        } else if (error.request) {
          swal.fire({
            icon: "error",
            title: "Error Requset",
            text: error.request.text,
          });
        } else {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: error,
          });
        }
      }
    },

    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },

    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    labelWithCity({ CardName, City, County }) {
      return `${CardName} -${City} — ${County}`;
    },

    labelcontract({ Name, Tel1, Position }) {
      return `${Name} -${Tel1} — ${Position}`;
    },

    async getCustomerAll(Id) {
      try {
        var response = await this.$http({
          method: "get",
          url: "api/SaleMan/GetCustomerAll/" + Id,
        });

        if (response.status == 200) {
          this.data.customer = [];

          this.obj.customer = response.data.model;
        }
      } catch (error) {
        if (error.response.status == 404) {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.response.data,
          });
        } else if (error.response.status == 401) {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: "ไม่พบสิทธิ์การใช้งาน",
          });
        } else if (error.response.status == 403) {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: "ไม่พบสิทธิ์การใช้งาน",
          });
        } else if (error.response.status == "500") {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.response.data.detail,
          });
        } else if (error.response.status == "400") {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.response.data,
          });
        } else if (error.request) {
          swal.fire({
            icon: "error",
            title: "Error Requset",
            text: error.request.text,
          });
        } else {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: error,
          });
        }
      }
    },

    async getcontract(Id) {
      try {
        var response = await this.$http({
          method: "get",
          url:
            "api/SaleMan/Getcontract/" +
            Id +
            "/" +
            window.localStorage.getItem("dbId"),
        });

        if (response.status == 200) {
          this.data.contract = [];
          this.obj.contract = response.data.ocpr;
        }
      } catch (error) {
        if (error.response.status == 404) {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.response.data.title,
          });
        } else if (error.response.status == 401) {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: "ไม่พบสิทธิ์การใช้งาน",
          });
        } else if (error.response.status == 403) {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: "ไม่พบสิทธิ์การใช้งาน",
          });
        } else if (error.response.status == "500") {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.response.data.detail,
          });
        } else if (error.response.status == "400") {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.response.data,
          });
        } else if (error.request) {
          swal.fire({
            icon: "error",
            title: "Error Requset",
            text: error.request.text,
          });
        } else {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: error,
          });
        }
      }
    },

    async getgroupCode() {
      try {
        var response = await this.$http({
          method: "get",
          url: "api/config/getgroupcode",
        });

        if (response.status == 200) {
          this.obj.groupCode = response.data.groupCode;
        }
      } catch (error) {
        if (error.response.status == 404) {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.response.data,
          });

          // this.$router.push("NotFound");
        } else if (error.response.status == 401) {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: "ไม่พบสิทธิ์การใช้งาน",
          });
        } else if (error.response.status == 500) {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: "ผิดพลาดเชื่อมต่อข้อมูล",
          });
        } else {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error,
          });
        }
      }
    },

    async getlearnCode() {
      try {
        var response = await this.$http({
          method: "Get",
          url: "api/Training/GetSubject",
        });

        if (response.status == 200) {
          this.obj.learnGroup = response.data;
        }
      } catch (error) {
        if (error.response.status == 404) {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.response.data.title,
          });
        } else if (error.response.status == 401) {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: "ไม่พบสิทธิ์การใช้งาน",
          });
        } else if (error.response.status == 403) {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: "ไม่พบสิทธิ์การใช้งาน",
          });
        } else if (error.response.status == 500) {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.response.data.detail,
          });
        } else if (error.response.status == 400) {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.request.responseText,
          });
        } else if (error.request) {
          swal.fire({
            icon: "error",
            title: "Error Requset",
            text: error.request.text,
          });
        } else {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: error,
          });
        }
      }
    },

    addclick() {
      this.button = {
        add: true,
        edit: true,
        delete: false,
        search: true,
        cancel: false,
        save: false,
      };

      this.data = {
        customer: [],
        groupCodeId: "",
        options: "1",
        contract: [],
        learnGroup: [],
        detail: "",
        activityId: "",
        docDate: "",
        editStatus: "",
      };
      this.survey = "";
      this.saleProcess = "";

      this.clearProcess = "";
      this.receipt = "";
      this.matayomProcess = "";
      this.bangkok = "";

      this.disabled = false;
      this.image = null;
      this.imageSrc = null;
    },

    editclick() {
      console.log(this.data.editStatus);
      if (this.data.editStatus == "Y") {
        swal.fire({
          icon: "info",
          title: "Alert",
          text: "รายการมีการยืนยันการปฏิบัติงานแล้ว",
        });

        return;
      }

      this.button = {
        add: true,
        edit: true,
        delete: false,
        search: true,
        cancel: false,
        save: false,
      };

      this.disabled = false;
    },

    deleteClick() {
      if (this.data.editStatus == "Y") {
        swal.fire({
          icon: "info",
          title: "Alert",
          text: "รายการมีการยืนยันการปฏิบัติงานแล้ว",
        });
        return;
      }

      swal
        .fire({
          title: "คุณต้องการลบรายการนี้ใช่ไหม",
          showCancelButton: true,
          confirmButtonText: "OK",
          denyButtonText: `Cancel`,
        })
        .then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.deleteActivity(this.data.activityId);
          }
        });
    },

    cancelClick() {
      this.data = {
        customer: [],
        groupCodeId: "",
        options: "1",
        contract: [],
        learnGroup: [],
        detail: "",
        activityId: "",
        docDate: "",
        editStatus: "",
      };

      this.image = null;
      this.imageSrc = null;

      this.survey = "";
      this.saleProcess = "";
      this.clearProcess = "";
      this.matayomProcess = "";
      this.receipt = "";

      this.button = {
        add: false,
        edit: true,
        delete: true,
        search: false,
        cancel: true,
        save: true,
      };

      this.disabled = true;
    },

    showModalcontract() {
      if (this.data.customer == "") {
        swal.fire({
          icon: "info",
          title: "Alert",
          text: "ยังไม่ได้เลือกลูกค้า",
        });
        return;
      }
      this.contract = {
        name: "",
        tel1: "",
        position: "",
      };
      this.$root.$emit("bv::show::modal", "modal-addcontract");
    },

    showmodalActivity() {
      this.datatable.items = [];
      this.$root.$emit("bv::show::modal", "modal-searchActivity");
    },

    async addcontract() {
      this.$v.contract.$touch();
      if (this.$v.contract.$pendding || this.$v.contract.$error) return;

      var params = new URLSearchParams();
      params.append("Name", this.contract.name);
      params.append("Tel1", this.contract.tel1);
      params.append("Position", this.contract.position);

      try {
        var response = await this.$http({
          method: "POST",
          url:
            "api/SaleMan/CreateConTract/" +
            this.data.customer.CardCode +
            "/" +
            window.localStorage.getItem("dbId"),
          params: params,
        });

        if (response.status == 200) {
          this.$root.$emit("bv::hide::modal", "modal-addcontract");
          this.getcontract(response.data.cardCode);
        }
      } catch (error) {
        console.log(error);
        if (error.response.status == 404) {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.response.data.title,
          });
        } else if (error.response.status == 401) {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: "ไม่พบสิทธิ์การใช้งาน",
          });
        } else if (error.response.status == 403) {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: "ไม่พบสิทธิ์การใช้งาน",
          });
        } else if (error.response.status == 500) {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.response.data.detail,
          });
        } else if (error.response.status == 400) {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.request.responseText,
          });
        } else if (error.request) {
          swal.fire({
            icon: "error",
            title: "Error Requset",
            text: error.request.text,
          });
        } else {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: error,
          });
        }
      }
    },

    async saveActivity() {
      this.$v.data.$touch();
      if (this.$v.data.$pendding || this.$v.data.$error) return;

      if (this.data.options == "1") {
        this.$v.survey.$touch();
        if (this.$v.survey.$pendding || this.$v.survey.$error) {
          return;
        }

        this.saleProcess = "";
        this.clearProcess = "";
        this.matayomProcess = "";
        this.bangkok = "";
        this.receipt = "";
      } else if (this.data.options == "2") {
        this.$v.saleProcess.$touch();
        if (this.$v.saleProcess.$pendding || this.$v.saleProcess.$error) {
          return;
        }

        this.survey = "";
        this.clearProcess = "";
        this.matayomProcess = "";
        this.bangkok = "";
        this.receipt = "";
      } else if (this.data.options == "3") {
        this.$v.clearProcess.$touch();
        if (this.$v.clearProcess.$pendding || this.$v.clearProcess.$error) {
          return;
        }

        if (this.clearProcess == "4" || this.clearProcess == "5") {
          this.$v.receipt.$touch();
          if (this.$v.receipt.$pendding || this.$v.receipt.$error) {
            return;
          }
        }

        this.survey = "";
        this.saleProcess = "";
        this.matayomProcess = "";
        this.bangkok = "";
      } else if (this.data.options == "4") {
        this.$v.matayomProcess.$touch();
        if (this.$v.matayomProcess.$pendding || this.$v.matayomProcess.$error) {
          return;
        }

        this.survey = "";
        this.saleProcess = "";
        this.clearProcess = "";
        this.bangkok = "";
        this.receipt = "";
      } else if (this.data.options == "5") {
        this.$v.bangkok.$touch();
        if (this.$v.bangkok.$pendding || this.$v.bangkok.$error) {
          return;
        }
        this.survey = "";
        this.saleProcess = "";
        this.clearProcess = "";
        this.matayomProcess = "";
        this.receipt = "";
      }

      try {
        if (this.receipt == "") {
          this.receipt = 0;
        }

        var data = {
          cardCode: this.data.customer.CardCode,
          groupCode: this.data.groupCodeId.groupCodeId.toString(),
          cntctCode: this.data.contract.CntctCode,
          detail: this.data.detail,
          survey: this.survey,
          saleProcess: this.saleProcess,
          clearProcess: this.clearProcess,
          matayomProcess: this.matayomProcess,
          bangkok: this.bangkok,
          receipt: this.receipt,
          activityId: this.data.activityId,
          docDate: "2000-01-01",
          SaleCode: window.localStorage.getItem("saleCode"),
          dbId: window.localStorage.getItem("dbId"),
          option: this.data.options,
        };

        var learnGroup = [];
        this.data.learnGroup.forEach((item) => {
          var obj = {};
          obj.id = 0;
          obj.activityId = this.data.activityId;
          obj.item = 0;
          obj.SubId = item.subId;
          learnGroup.push(obj);
        });

        var response = await this.$http({
          method: "Post",
          url: "api/SaleMan/SaveActivity",
          data: {
            activity: data,
            listGroup: learnGroup,
          },
        });

        if (response.status == 200) {
          if (this.image != null) {
            this.savePicture(response.data);
          }

          swal
            .fire({
              icon: "info",
              title: "บันทึกการปฏิบัติงานเรียบร้อย",
              confirmButtonText: "ตกลง",
            })
            .then((result) => {
              if (result) {
                this.activityId = response.data;

                this.data = {
                  customer: [],
                  groupCodeId: "",
                  options: "1",
                  contract: [],
                  learnGroup: [],
                  detail: "",
                  activityId: "",
                  docDate: "",
                  editStatus: "",
                };

                this.survey = "";
                this.saleProcess = "";

                this.clearProcess = "";
                this.receipt = "";
                this.matayomProcess = "";
                this.bangkok = "";

                this.button = {
                  add: false,
                  edit: true,
                  delete: true,
                  search: false,
                  save: true,
                  cancel: false,
                };
              }
            });
        }
      } catch (error) {
        if (error.response.status == 404) {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.response.data.title,
          });
        } else if (error.response.status == 401) {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: "ไม่พบสิทธิ์การใช้งาน",
          });
        } else if (error.response.status == 403) {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: "ไม่พบสิทธิ์การใช้งาน",
          });
        } else if (error.response.status == 500) {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.response.data.detail,
          });
        } else if (error.response.status == 400) {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.request.responseText,
          });
        } else if (error.request) {
          swal.fire({
            icon: "error",
            title: "Error Requset",
            text: error.request.text,
          });
        } else {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: error,
          });
        }
      }
    },

    async savePicture(id) {
      try {
        var fdata = new FormData();
        fdata.append("file", this.image);

        var response = await this.$http({
          method: "Post",
          url: "api/SaleMan/SavePictureActivity/" + id,
          data: fdata,
        });

        if (response.status == "200") {
          this.image = null;
          this.imageSrc = null;
        }
      } catch (error) {
        console.log(error);

        if (error.response.status == 404) {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.response.data.title,
          });
        } else if (error.response.status == 401) {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: "ไม่พบสิทธิ์การใช้งาน",
          });
        } else if (error.response.status == 403) {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: "ไม่พบสิทธิ์การใช้งาน",
          });
        } else if (error.response.status == 500) {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.response.data.detail,
          });
        } else if (error.response.status == 400) {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.request.responseText,
          });
        } else if (error.request) {
          swal.fire({
            icon: "error",
            title: "Error Requset",
            text: error.request.text,
          });
        } else {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: error,
          });
        }
      }
    },

    formatDate(value) {
      if (value == null) {
        return;
      }
      return moment(String(value)).format("DD/MM/YYYY ");
    },

    async getActivity() {
      try {
        this.$v.search.$touch();
        if (this.$v.search.$pendding || this.$v.search.$error) return;

        var params = new URLSearchParams();
        params.append("fromDate", this.search.fromDate);
        params.append("todate", this.search.toDate);

        var response = await this.$http({
          method: "Post",
          url:
            "api/SaleMan/GetActivity/" +
            window.localStorage.getItem("saleCode") +
            "/" +
            window.localStorage.getItem("dbId"),
          params: params,
        });

        if (response.status == 200) {
          var data = [];
          response.data.forEach((item) => {
            var cus = this.obj.customer.filter(
              (f) => f.CardCode == item.cardCode
            );

            if (cus.length > 0) {
              item.cardName = cus[0].CardName;
            }

            data.push(item);
          });

          this.datatable.items = data;
        }
      } catch (error) {
        console.log(error);

        if (error.response.status == 404) {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.response.data.title,
          });
        } else if (error.response.status == 401) {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: "ไม่พบสิทธิ์การใช้งาน",
          });
        } else if (error.response.status == 403) {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: "ไม่พบสิทธิ์การใช้งาน",
          });
        } else if (error.response.status == 500) {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.response.data.detail,
          });
        } else if (error.response.status == 400) {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.request.responseText,
          });
        } else if (error.request) {
          swal.fire({
            icon: "error",
            title: "Error Requset",
            text: error.request.text,
          });
        } else {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: error,
          });
        }
      }
    },

    async selectActivity(id) {
      try {
        var response = await this.$http({
          method: "Post",
          url: "api/SaleMan/selectactivity/" + id,
        });

        if (response.status == 200) {
          var customer = this.obj.customer.filter(
            (f) => f.CardCode == response.data.model.cardCode
          );
          this.data.customer = [];
          this.data.customer = customer[0];

          this.data.learnGroup = [];
          response.data.learnGroup.forEach((item) => {
            var obj = this.obj.learnGroup.filter((f) => f.subId == item.subId);

            if (obj.length > 0) {
              this.data.learnGroup.push(obj[0]);
            }
          });

          this.data.options = response.data.model.option;
          this.data.groupCodeId = [];

          var groupCode = this.obj.groupCode.filter(
            (f) => f.groupCodeId == response.data.model.groupCode
          );

          this.data.groupCodeId = groupCode[0];

          this.survey = response.data.model.survey;
          this.saleProcess = response.data.model.saleProcess;
          this.clearProcess = response.data.model.clearProcess;
          this.matayomProcess = response.data.model.matayomProcess;
          this.bangkok = response.data.model.bangkok;
          this.receipt = response.data.model.receipt;
          this.data.detail = response.data.model.detail;
          this.data.activityId = response.data.model.activityId;
          this.data.editStatus = response.data.model.editStatus;

          this.data.docDate = response.data.model.docDate;

          await this.getcontract(response.data.model.cardCode);

          var contract = await this.obj.contract;

          var objcontract = contract.filter(
            (f) => f.CntctCode == response.data.model.cntCtCode
          );

          this.data.contract = [];
          this.data.contract = objcontract[0];

          this.button = {
            add: false,
            edit: false,
            delete: false,
            search: false,
            cancel: false,
            save: true,
          };

          if (response.data.model.fileName != null) {
            this.getImage(response.data.model.fileName);
          }

          this.disabled = true;
          this.$root.$emit("bv::hide::modal", "modal-searchActivity");
        }
      } catch (error) {
        if (error.response.status == 404) {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.response.data.title,
          });
        } else if (error.response.status == 401) {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: "ไม่พบสิทธิ์การใช้งาน",
          });
        } else if (error.response.status == 403) {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: "ไม่พบสิทธิ์การใช้งาน",
          });
        } else if (error.response.status == 500) {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.response.data.detail,
          });
        } else if (error.response.status == 400) {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.request.responseText,
          });
        } else if (error.request) {
          swal.fire({
            icon: "error",
            title: "Error Requset",
            text: error.request.text,
          });
        } else {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: error,
          });
        }
      }
    },

    async getImage(id) {
      try {
        var response = await this.$http({
          method: "get",
          url: "api/SaleMan/GetPicActivity/" + id,
          responseType: "arraybuffer",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });

        if (response.status == 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          this.imageSrc = url;
        }
      } catch (error) {
        if (error.response.status == 404) {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.response.data.title,
          });
        } else if (error.response.status == 401) {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: "ไม่พบสิทธิ์การใช้งาน",
          });
        } else if (error.response.status == 403) {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: "ไม่พบสิทธิ์การใช้งาน",
          });
        } else if (error.response.status == 500) {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.response.data.detail,
          });
        } else if (error.response.status == 400) {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.request.responseText,
          });
        } else if (error.request) {
          swal.fire({
            icon: "error",
            title: "Error Requset",
            text: error.request.text,
          });
        } else {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: error,
          });
        }
      }
    },

    async deleteActivity(id) {
      try {
        var response = await this.$http({
          method: "delete",
          url: "api/SaleMan/DeleteActivity/" + id,
        });

        if (response.status == 200) {
          this.cancelClick();
        }
      } catch (error) {
        console.log(error);

        if (error.response.status == 404) {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.response.data.title,
          });
        } else if (error.response.status == 401) {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: "ไม่พบสิทธิ์การใช้งาน",
          });
        } else if (error.response.status == 403) {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: "ไม่พบสิทธิ์การใช้งาน",
          });
        } else if (error.response.status == 500) {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.response.data.detail,
          });
        } else if (error.response.status == 400) {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.request.responseText,
          });
        } else if (error.request) {
          swal.fire({
            icon: "error",
            title: "Error Requset",
            text: error.request.text,
          });
        } else {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: error,
          });
        }
      }
    },
  },

  mounted() {
    this.getgroupCode();

    var dbId = window.localStorage.getItem("dbId");

    if (dbId != null) {
      this.getCustomerAll(dbId);
    }

    this.getlearnCode();
  },

  validations: {
    search: {
      fromDate: { required },
      toDate: { required },
    },

    contract: {
      name: { required },
      tel1: { required },
      position: { required },
    },

    data: {
      customer: { required },
      groupCodeId: { required },
      contract: { required },
      learnGroup: { required },
      detail: { required },
      options: { required },
    },

    survey: { required },
    saleProcess: { required },
    clearProcess: { required },
    receipt: { required },
    matayomProcess: { required },
    bangkok: { required },
  },
};
</script>
